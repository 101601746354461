import { useLocation } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import ISOCountries from 'i18n-iso-countries';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _property from 'lodash/property';
import _uniq from 'lodash/uniq';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState, useMemo } from 'react';
import productTypes from '../../../utils/ProductUtils';

import FieldLink from '../../field/FieldLink/FieldLink';
import './valuers-view.scss';

/**
 * Component for rendering a list of members with a filter.
 */
const ValuersView = () => {
    ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    const rootUrl =
        process.env.GATSBY_API_URL +
        `/jsonapi/index/registrations?sort=label&page[limit]=20`;
    const [registrations, setRegistrations] = useState([]);
    const location = useLocation();
    const { members } = useStaticQuery(graphql`
        query {
            members: allNodeMemberAssociationPage(
                sort: {
                    fields: [
                        relationships___field_member_association___member_key
                    ]
                    order: [ASC]
                }
            ) {
                edges {
                    node {
                        ...nodeMemberAssociationPageListFragment
                    }
                }
            }
        }
    `);
    const filters = useMemo(() => [
        {
            filter: `fulltext`,
            label: `Name, city or certificate`,
            name: `keywords`,
        },
        {
            filter: `member_key`,
            label: `Awarding member association`,
            options: _uniq(
                _map(
                    _filter(members.edges, (member) => {
                        return productTypes(member.node.r.association).length > 0;
                    }),
                   _property('node.r.association.key'),
                )
            ),
            name: `member`,
        },
        {
            filter: `bundle`,
            label: `Recognition type`,
            options: [
                { key: 'rev', label: 'REV' },
                { key: 'trv', label: 'TRV' },
                { key: 'rev_bv', label: 'REV-BV' },
                { key: 'rev_pme', label: 'REV-PME' },
            ],
            name: `type`,
        },
        {
            filter: `country`,
            label: `Country`,
            options: _map(
                _uniq(
                    _map(
                        members.edges,
                        _property('node.r.association.country'),
                    ),
                ),
                (country_code) => {
                    return {
                        key: country_code,
                        label: ISOCountries.getName(country_code, 'en', {
                            select: 'official',
                        }),
                    };
                },
            ),
            name: `country`,
        },
    ], [members.edges]);

    const goToPage = (url) => {
        fetch(url)
            .then((response) => response.json()) // parse JSON from request
            .then((resultData) => {
                setRegistrations(resultData);
            }); // set data for the number of stars
    };

    const goToNextPage = () => {
        if (registrations.links.next) {
            goToPage(registrations.links.next.href);
        }
    };

    const goToPrevPage = () => {
        if (registrations.links.prev) {
            goToPage(registrations.links.prev.href);
        }
    };

    const goToFirstPage = () => {
        if (registrations.links.first) {
            goToPage(registrations.links.first.href);
        }
    };

    const goToLastPage = () => {
        if (registrations.links.last) {
            goToPage(registrations.links.last.href);
        }
    };

    const applyFilters = (values, actions) => {
        // Navigate reach-router to the query parameters to keep history and make
        // it possible to bookmark links.
        navigate(`${location.pathname}?${queryString.stringify(values)}`);
        actions.setSubmitting(false);
    };

    const toggleInfo = (event) => {
        event.target.parentElement.parentElement.classList.toggle(
            'view-more--open',
        );
        event.target.querySelector('.bi').classList.toggle('bi-chevron-right');
        event.target.querySelector('.bi').classList.toggle('bi-chevron-down');
        event.preventDefault();
    };

    // Client-side code, only executed in the browser. Initial state when page
    // loads or when the query parameters change.
    useEffect(() => {
        // Fetch the currently active filters.
        // Set default form values based on the query parameters in the URL.
        const values = queryString.parse(location.search);
        // Update the results.
        let filterValues = {};
        filters.forEach((filter) => {
            if (values[filter.name] && values[filter.name] !== '') {
                filterValues[`filter[${filter.filter}]`] = values[filter.name];
            }
        });

        filterValues = queryString.stringify(filterValues);
        filterValues = filterValues === '' ? '' : '&' + filterValues;
        goToPage(rootUrl + filterValues);
        // Make sure useEffect is called when query params are changed.
    }, [location.search, filters, rootUrl]);

    return (
        <div className="valuers-view">
            <Formik
                onSubmit={applyFilters}
                initialValues={queryString.parse(location.search)}
                enableReinitialize={true}>
                <Form>
                    {filters.map((filter) => {
                        return (
                            <p className="form-item" key={filter.name}>
                                <label htmlFor={filter.name}>
                                    {filter.label}:{' '}
                                </label>
                                <span className="input__wrapper">
                                    {filter.options ? (
                                        <Field
                                            component="select"
                                            name={filter.name}>
                                            <option value="">- Select -</option>
                                            {filter.options.map((option) => {
                                                if (
                                                    typeof option === 'string'
                                                ) {
                                                    option = {
                                                        key: option,
                                                        label: option,
                                                    };
                                                }
                                                return (
                                                    <option key={option.key} value={option.key}>
                                                        {option.label}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    ) : (
                                        <Field name={filter.name} />
                                    )}
                                    <i className="bi bi-chevron-down"></i>
                                </span>
                            </p>
                        );
                    })}
                    <div className="d-md-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary btn-submit">
                            Search
                        </button>
                    </div>
                </Form>
            </Formik>

            <div className="info-box d-flex">
                <i className="bi bi-info-circle"></i>
                <p>
                    To contact a valuer please contact her/his association
                    (click on "View more" and click on the association name for
                    more information).
                </p>
            </div>

            <div className="valuers-view--table">
                <div className="valuers-view--table-th d-flex flex-wrap">
                    <div className="valuer-name">Name</div>
                    <div className="valuer-city">City</div>
                    <div className="valuer-certificate">Certificate</div>
                </div>
                {registrations.data !== undefined &&
                    registrations.data.map((registration) => {
                        return (
                            <div
                                className="valuers-view--table-body d-flex flex-wrap"
                                key={registration.attributes.certificate_id}>
                                <div className="valuer-name">
                                    {registration.attributes.registrant_name}
                                </div>
                                <div className="valuer-city">
                                    {registration.attributes.registrant_city}
                                </div>
                                <div className="valuer-certificate">
                                    {registration.attributes.certificate_id}
                                </div>
                                <div className="view-more">
                                    <button
                                        className="view-more--link"
                                        onClick={toggleInfo}>
                                        View more{' '}
                                        <i className="bi bi-chevron-right"></i>
                                    </button>
                                </div>
                                <div className="flex-100 view-more--content">
                                    <dl>
                                        <dt>Issued on:</dt>
                                        <dd>
                                            <time
                                                dateTime={
                                                    registration.attributes
                                                        .start_date
                                                }>
                                                {moment(
                                                    registration.attributes
                                                        .start_date,
                                                ).format('DD-MM-YYYY')}
                                            </time>
                                        </dd>
                                    </dl>
                                    {registration.attributes.publisher_info &&
                                        registration.attributes.publisher_info
                                            .length && (
                                            <dl>
                                                <dt>Awarded by:</dt>
                                                <dd>
                                                    {registration.attributes.publisher_info.map(
                                                        (publisher) => {
                                                            return (
                                                                <p key={publisher.member_key}>
                                                                    {publisher.url_alias ? (
                                                                        <FieldLink
                                                                            link={{
                                                                                uri: publisher.url_alias,
                                                                            }}>
                                                                            {
                                                                                publisher.member_key
                                                                            }{' '}
                                                                            (
                                                                            {
                                                                                publisher.name_en
                                                                            }
                                                                            )
                                                                        </FieldLink>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                publisher.member_key
                                                                            }{' '}
                                                                            (
                                                                            {
                                                                                publisher.name_en
                                                                            }
                                                                            )
                                                                        </>
                                                                    )}
                                                                </p>
                                                            );
                                                        },
                                                    )}
                                                </dd>
                                            </dl>
                                        )}
                                </div>
                            </div>
                        );
                    })}
            </div>

            <div className="pager">
                {registrations.links && registrations.links.first !== undefined && (
                    <button onClick={() => goToFirstPage()}>
                        <i className="bi bi-chevron-double-left"></i> First
                    </button>
                )}
                {registrations.links && registrations.links.prev !== undefined && (
                    <button onClick={() => goToPrevPage()} className="prev">
                        <i className="bi bi-chevron-left"></i> Prev
                    </button>
                )}
                {registrations.links && registrations.links.next !== undefined && (
                    <button onClick={() => goToNextPage()} className="next">
                        Next <i className="bi bi-chevron-right"></i>
                    </button>
                )}
                {registrations.links && registrations.links.last !== undefined && (
                    <button onClick={() => goToLastPage()}>
                        Last <i className="bi bi-chevron-double-right"></i>
                    </button>
                )}
            </div>
        </div>
    );
};

export default ValuersView;
