/**
 * Tegova product helper functions.
 */
import _has from 'lodash/has';

import rev_logo from '../images/ama.png';
import trv_logo from '../images/ama-trv.png';
import rev_bv_logo from '../images/ama-rev_bv.png';
import rev_pme_logo from '../images/ama-rev_pme.png';

/**
 * Returns the product statistics for an association taking publishers into
 * account.
 *
 * @param {*} association
 *   The tegova_association object.
 */
const productTypes = (association) => {
    const productTypes = [
        { type: 'rev', singular: 'REV', plural: 'REVs', logo: rev_logo },
        { type: 'trv', singular: 'TRV', plural: 'TRVs', logo: trv_logo },
        { type: 'rev_bv', singular: 'REV-BV', plural: 'REV-BVs', logo: rev_bv_logo },
        { type: 'rev_pme', singular: 'REV-PME', plural: 'REV-PMEs', logo: rev_pme_logo },
    ];

    let productStats = [];
    productTypes.forEach((productType) => {
        if (
            _has(
                association,
                'r.' + productType.type + '[0].registration_count_active',
            )
        ) {
            // Don't display inactive products.
            if (!association.r[productType.type][0].status) {
                return;
            }
            if (
                _has(
                    association,
                    'r.' + productType.type + '[0].r.publisher',
                ) &&
                association.r[productType.type][0].r.publisher.length > 0
            ) {
                let isPublisher = false;
                association.r[productType.type][0].r.publisher.forEach(
                    (publisher) => {
                        if (publisher.id === association.id) {
                            isPublisher = true;
                        }
                    },
                );
                // If the product has publishers and the current association is not
                // one of them, the registrations are not published by this
                // association.
                if (!isPublisher) {
                    return;
                }
            }

            productType.registrations = association.r[productType.type][0].registration_count_active;
            productStats.push(productType);
        }
    });

    return productStats;
};

export default productTypes;
