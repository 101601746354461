import React from 'react';

import ContentBreadcrumb from '../components/content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../components/content/ContentHeader/ContentHeader';
import MemberAwardingList from '../components/content/MemberAwardingList/MemberAwardingList';
import ValuersView from '../components/content/ValuersView/ValuersView';
import Layout from '../components/layout/layout';
import Seo from '../components/layout/seo';

const ValuersPage = ({ pageContext }) => {
    let {
        breadcrumb: { crumbs },
    } = pageContext;

    return (
        <Layout>
            <Seo title="Recognised valuers register" />
            <ContentBreadcrumb crumbs={crumbs} label="Recognised valuers" />
            <ContentHeader title="Recognised valuers register" />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Search for REV, REV-BV, REV-PME and TRV recognised valuers.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <ValuersView />
                    </div>
                    <div className="col-md-4">
                        <MemberAwardingList />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ValuersPage;
