import { graphql, useStaticQuery } from 'gatsby';
import _forOwn from 'lodash/forOwn';
import _has from 'lodash/has';
import React from 'react';

import productTypes from '../../../utils/ProductUtils';
import FieldLink from '../../field/FieldLink/FieldLink';
import './member-awarding-list.scss';

/**
 * Component for rendering a list of members with a filter.
 */
const MemberAwardingList = () => {
    // Get list of tegova_products with:
    // - the association as association_id
    // - OR the association as publisher
    // - if the tegova_product has 1 or more publishers this association must be a
    //   publisher
    const { members } = useStaticQuery(
        graphql`
            query {
                members: allNodeMemberAssociationPage(
                    filter: { relationships: { field_member_association: { member_key: { ne: null } } } }
                    sort: { fields: [relationships___field_member_association___member_key], order: [ASC] }
                ) {
                    edges {
                        node {
                            ...nodeMemberAssociationPageListFragment
                        }
                    }
                }
            }
        `,
    );

    let productStats = {};
    let memberProductStats = {};

    // GraphQL doesn't sort case insensitive.
    members.edges.sort(function(a, b) {
        if (!a.node.r?.association?.key) {
            console.log(a.node)
        }
        let memberA = a.node.r.association.key;
        let memberB = b.node.r.association.key;
        memberA = memberA.toLowerCase();
        memberB = memberB.toLowerCase();
        if ( memberA == memberB) return 0;
        return memberA < memberB ? -1 : 1;
    });

    members.edges.forEach((member) => {
        let association = member.node.r.association;
        memberProductStats[association.key] = [];
        productTypes(association).forEach((stat) => {
            if (stat.registrations > 0) {
                // Calculate total per member / product type.
                memberProductStats[association.key].push(
                    stat.registrations +
                        ' ' +
                        (stat.registrations > 1 ? stat.plural : stat.singular),
                );
                // Calculate total per product type.
                if (!_has(productStats, stat.type)) {
                    productStats[stat.type] = {
                        total: 0,
                        stat: stat,
                    };
                }

                productStats[stat.type].total += stat.registrations;
            }
        });
    });

    let totalProductStats = '';
    if (productStats) {
        totalProductStats = []
        _forOwn(productStats, (stat) => {
            totalProductStats.push(stat.total + ' ' + (stat.total > 1 ? stat.stat.plural : stat.stat.singular))
        });
        totalProductStats = 'Total: ' + totalProductStats.join(', ');
    }

    return (
        <div className="member-awarding-list">
            <h4>Awarding Member Associations</h4>
            <p>
                To date, the awarding member associations which have the right
                to award recognitions are:
            </p>
            <ul className="member-awarding-list--list">
                {members.edges.map(({ node }, i) => {
                    if (
                        _has(memberProductStats, node.r.association.key) &&
                        memberProductStats[node.r.association.key].length > 0
                    ) {
                        const memberKey = `AMA-${node.r.association.key}`
                        return (
                            <li key={memberKey}>
                                <FieldLink link={{ uri: node.path.alias }}>
                                    {node.r.association.key}
                                </FieldLink>
                                {_has(node, 'r.association.name_en') && (
                                    <> ({node.r.association.name_en}) </>
                                )}
                                <FieldLink
                                    link={{
                                        uri:
                                            '/valuers?member=' +
                                            node.r.association.key,
                                    }}>
                                    (
                                    {memberProductStats[node.r.association.key].join(
                                        ', ',
                                    )}
                                    )
                                </FieldLink>
                            </li>
                        );
                    }
                    return (<></>)
                })}
                <strong className="total">{ totalProductStats }</strong>
            </ul>
        </div>
    );
};

export default MemberAwardingList;
